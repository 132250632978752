import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: ['Amiko'].join()
  },
  palette: {
    mode: 'light',
    primary: {
      light: '#FFFFFF',
      main:'#333333',
      dark: '#000000',
      contrastText: '#000000' 
    },
    text: {
      primary: '#000000' 
    },   
  }  
});

export default theme;
