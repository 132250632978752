import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Button, Checkbox, FormControl, MenuItem, Select, TableRow, TableBody, Table, TableCell, TextField, Box, Container, Typography, SelectChangeEvent, SxProps, IconButton, Grid, Card, CardHeader, CardContent } from "@mui/material";
import { FC, useState } from "react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import emailjs from '@emailjs/browser';
import { init } from '@emailjs/browser';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import { WindowDialog } from "../common/WindowDialog";
import { useForm, SubmitHandler } from "react-hook-form";
import { BackButton } from "../common/BackButton";
import { SolveAddress } from "../common/Coordinate";
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import locale from "date-fns/locale/es";
import moment from 'moment';
import 'moment/locale/es';
import { GOOGLE_ADS, SendConversion } from "../googleAds/GoogleAdsMethods";

type AppointmentInputs = {
  address: string,
  comments: string,

  customerName: string,
  date: Date | null,
  email: string,
  phone: string | null,
  professionals: number,
  customers: number,

  maquillaje?: boolean,
  corte?: boolean,
  unas?: boolean,
  peinado?: boolean
};

const cardStyle: SxProps = {
  margin: '0.7vw',
  "&:hover":
  {
    backgroundColor: 'whiteSmoke'
  },
  backgroundColor: 'white'
}


export const Appointment: FC<{}> = () => {

  init("bHkeDmcXr4ItPH_Ie");

  const [date, setDate] = useState<Date | null>(new Date());
  const [phone, setPhone] = useState<string | null>('null');
  const [professionals, setProfessionals] = useState<string>('1');
  const [customers, setCustomers] = useState<string>('1');


  const [maquillaje, setMaquillaje] = useState<boolean | undefined>(false);
  const [corte, setCorte] = useState<boolean | undefined>(false);
  const [unas, setUnas] = useState<boolean | undefined>(false);
  const [peinado, setPeinado] = useState<boolean | undefined>(false);


  const onMaquillajeChange = (event: React.ChangeEvent<HTMLInputElement>) => setMaquillaje(event.target.checked);
  const onCorteChange = (event: React.ChangeEvent<HTMLInputElement>) => setCorte(event.target.checked);
  const onUnasChange = (event: React.ChangeEvent<HTMLInputElement>) => setUnas(event.target.checked);
  const onPeinadoChange = (event: React.ChangeEvent<HTMLInputElement>) => setPeinado(event.target.checked);

  const [openDialog, setOpenDialog] = useState<boolean | undefined>(false);
  const onChangeDate = (value: Date | null) => { setDate(value) };
  const onChangePhone = (value: any) => { setPhone(value) };

  const onChangeProfessionals = (event: SelectChangeEvent) => {
    setProfessionals(event.target.value as string);
  };

  const onChangeCustomers = (event: any) => {
    setCustomers(event.target.value);
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const startValues: AppointmentInputs =
  {
    address: '',
    comments: '',
    corte: false,
    customerName: '',
    date: new Date(),
    email: '',
    maquillaje: false,
    phone: '',
    professionals: 1,
    customers: 1,
    unas: false,
    peinado: false
  };

  const { register, handleSubmit, setValue, formState: { errors } } = useForm<AppointmentInputs>(
    {
      mode: 'onChange',
      defaultValues: startValues
    });

  const onSubmit: SubmitHandler<AppointmentInputs> = data => {

    console.clear();
    let emailData =
    {
      // campos obligatorios
      address: data.address,
      customerName: data.customerName,
      email: data.email,
      customers: data.customers,
      comments: data.comments,

      // opcionales o no necesitan validacion
      professionals: professionals,
      date: moment(date).locale('es').format('LLLL'),
      phone: phone,

      maquillaje: maquillaje ? 'SI' : 'NO',
      corte: corte ? 'SI' : 'NO',
      unas: unas ? 'SI' : 'NO',
      peinado: peinado ? 'SI' : 'NO',
    };

    //console.log(emailData);

    emailjs.send('service_x3clu1f', 'release_template', emailData)
      .then(function (response) {

        // Notifica la conversion en google ads
        SendConversion(GOOGLE_ADS.APPOINTMENT);
        setOpenDialog(true);
      }, function (error) {
        console.log('FAILED...', error);
      });
  }

  // Carga la direccion local  
  const LoadAutomaticAddress = async () => {
    let r = await SolveAddress();
    setValue('address', r);
  }

  const OnAutoComplete = (e: any) => {
    LoadAutomaticAddress();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container fixed>
        <FormControl>
          <BackButton url='/'></BackButton>

          <Typography textAlign='center' sx={{ fontWeight: 600 }}>
            AGENDA TU DÍA PARA TUS SERVICIOS
          </Typography>

          <Card sx={cardStyle}>
            <CardHeader title={<Typography >Tu nombre</Typography>} />
            <CardContent component='div'>
              <TextField
                id="outlined-required"
                label="Nombres Apellidos"
                fullWidth
                {...register('customerName', { required: "Ingresa tu nombre" })}
                error={!!errors.customerName}
                helperText={errors?.customerName ? errors.customerName.message : null}
              />
            </CardContent>
          </Card>

          <Card sx={cardStyle}>
            <CardHeader title={<Typography >Dirección</Typography>} />
            <CardContent component='div'>
              <Grid container>
                <Grid item xs={11}>
                  <TextField
                    id="outlined-required"
                    fullWidth
                    placeholder="Ingrese su dirección"
                    {...register('address', { required: "Ingresa tu dirección" })}
                    error={!!errors.address}
                    helperText={errors?.address ? errors.address.message : null}
                  />
                </Grid>
                <Grid item xs={1} >
                  <IconButton style={{ marginTop: '10px' }} onClick={OnAutoComplete}  >
                    <GpsFixedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card sx={cardStyle}>
            <CardHeader title={<Typography >Correo Electrónico</Typography>} />
            <CardContent component='div'>
              <TextField
                id="outlined-required"
                label="Correo Electrónico"
                fullWidth
                {...register("email", {
                  required: "Correo es requerido",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Dirección inválida",
                  },
                })}
                error={!!errors.email}
                helperText={errors?.email ? errors.email.message : null}
              />
            </CardContent>
          </Card>

          <Card sx={cardStyle}>
            <CardHeader title={<Typography >Teléfono de Contacto</Typography>} />
            <CardContent component='div' sx={{ textAlign: 'center' }}>
              <MuiPhoneNumber
                defaultCountry={'co'}
                value={phone}
                onChange={onChangePhone}
                required
              />
            </CardContent>
          </Card>

          <Card sx={cardStyle} >
            <CardHeader title={<Typography >Elige la Fecha</Typography>} />
            <CardContent component='div' sx={{ padding: '1vh !important', textAlign: 'center' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale} sx={{ width: '100%' }}>
                <DateTimePicker
                  renderInput={(props: any) => <TextField fullWidth {...props} />}
                  label="Horario que deseas la cita"
                  value={date}
                  onChange={onChangeDate}
                  inputFormat='E, MMM/dd/yyyy h:mm a'
                />
              </LocalizationProvider>
            </CardContent>
          </Card>

          <Card sx={cardStyle}>
            <CardHeader title={<Typography >Cuantos profesionales necesitas?</Typography>} />
            <CardContent component='div'>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                value={professionals}
                onChange={onChangeProfessionals}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
            </CardContent>
          </Card>

          <Card sx={cardStyle}>
            <CardHeader title={<Typography >Personas para atender</Typography>} />
            <CardContent component='div'>
              <TextField
                id="txtCustomers"
                fullWidth
                {...register('customers', { required: "Cuantas personas en la atención" })}
                onChange={onChangeCustomers}
                error={!!errors.customers}
                helperText={errors?.customers ? errors.customers.message : null}
              />
            </CardContent>
          </Card>

          <Card sx={cardStyle}>
            <CardHeader title={<Typography >Servicios</Typography>} />
            <CardContent component='div'>
              <Table >
                <TableBody>
                  <TableRow >
                    <TableCell sx={{ border: 0 }} >
                      Alisado Profesional
                      <Typography fontSize='12px'>
                        Servicio de alisado
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: 0 }} >
                      <Checkbox {...label}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color="secondary"
                        checked={maquillaje}
                        value={maquillaje}
                        onChange={onMaquillajeChange}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: 0 }} >
                      Corte
                      <Typography fontSize='12px'>
                        Pelo e Imagen
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: 0 }} >
                      <Checkbox
                        {...label}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color="secondary"
                        checked={corte}
                        value={corte}
                        onChange={onCorteChange}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ border: 0 }} >
                      Peinado
                      <Typography fontSize='12px' textAlign='left'>
                        Trenzas, Ondas, Cepillados.
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: 0 }} >
                      <Checkbox
                        {...label}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color="warning"
                        checked={peinado}
                        value={peinado}
                        onChange={onPeinadoChange}
                      />
                    </TableCell>
                  </TableRow>
                  
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <Card sx={cardStyle}>
            <CardHeader title={<Typography >Comentarios</Typography>} />
            <CardContent component='div'>
              <TextField
                id="outlined-required"
                multiline
                rows={4}
                label="Alguna indicacion adicional?"
                fullWidth
                {...register('comments')}
              />
            </CardContent>
          </Card>

          <Box style={{ position: 'fixed', bottom: '12vh', left: '50%', transform: 'translateX(-50%)' }}>
            <Button aria-haspopup="listbox"
              aria-controls="lock-menu"
              variant="contained"
              style={{ marginLeft: "auto" }}
              type='submit'
              sx={{ minWidth: '50%' }} >
              Pedir Servicio
            </Button>
          </Box>


        </FormControl>

        <WindowDialog
          title="Se ha realizado su solicitud"
          message="Su petición se ha realizado correctamente, en breve te contactaremos para confirmar la cita"
          handleOpen={openDialog}
        />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
    </form >

  );
}