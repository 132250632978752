// Función que determina si una dirección IP está asociada con un proveedor de VPN
export async function isVpn2(ip: string): Promise<boolean> {
  try {

    let apiKey = '1d0d4dd90f5c4899439024a8f52597f0';
    let url = `http://api.ipapi.com/api/${ip}?access_key = ${apiKey}`;

    // Hace una solicitud a la API de ipapi para obtener información geográfica de la dirección IP
    const response = await fetch(url);
    const data = await response.json();

    // Si la respuesta indica que la dirección IP pertenece a un proveedor de VPN, se considera no válido
    return data?.security?.is_proxy || data?.security?.is_vpn;
  } catch (err) {
    console.error(`Error al leer la información geográfica de la dirección IP: ${err}`);
    return true; // Se considera como no válido si ocurre algún error
  }
}

export async function isVpn(ip: string): Promise<boolean> {

  let apiKey = 'be0fc93afd2b35e8064e12e66b3672110e0c8fdc343830016a3a6c49';
  let url=`https://api.ipdata.co?api-key=${apiKey}`;

  const response = await fetch(url, {
    headers: {
      'Accept': 'application/json'
    }
  });
  const vpn = await response.text();
  return vpn === '1';
}
