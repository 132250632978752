import React from 'react';
import { motion } from 'framer-motion';

interface DiscountBannerProps {
  discountText: string;
}

const DiscountBanner: React.FC<DiscountBannerProps> = ({ discountText }) => {
  const bannerVariants = {
    hidden: { opacity: 0, scale: 0.8, rotate: 0 },
    visible: { 
      opacity: 1, 
      scale: 1.1, 
      rotate: [-10, 10, -10, 10, 0], 
      transition: { 
        duration: 1, 
        repeat: Infinity, 
        repeatType: "reverse" as const 
      }
    }
  };

  const textVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1, 
      transition: { delay: 0.5, duration: 1 } 
    }
  };

  return (
    <motion.div 
      className="discount-banner"
      initial="hidden"
      animate="visible"
      variants={bannerVariants}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '70vw',   // 70% del ancho de la pantalla
        height: '70vw',  // 70% del ancho para mantenerlo circular
        maxWidth: '250px', // Tamaño máximo para pantallas grandes
        maxHeight: '250px',
        background: 'linear-gradient(to right, rgb(255, 182, 193), rgb(255, 239, 213), rgb(173, 216, 230))', // Colores pastel suaves
        borderRadius: '50%',
        boxShadow: '0 0 20px rgba(0,0,0,0.2)',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <motion.div 
        variants={textVariants}
        style={{
          fontSize: '6vw', // Texto responsivo basado en el ancho de la pantalla
          textAlign: 'center',
          color: '#ff0000',
          fontWeight: 'bold',
          textShadow: '2px 2px #000',
        }}
      >
        {discountText}
      </motion.div>
    </motion.div>
  );
}

export default DiscountBanner;
