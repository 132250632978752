import { getAddress } from "./Geocoding";

export const getCoordinates = () => {
    return new Promise<any>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((s) => {
            resolve({ lat: s.coords.latitude, lng: s.coords.longitude })
        });
    });
}

export async function LoadCoordinate() {

    let c = await getCoordinates();
    return {
        lat: c.lat,
        lng: c.lng
    };
};

export async function LoadGoogleCoordinate() {
    return await getCoordinates();
};

export async function SolveAddress() {

    let c = await LoadCoordinate();
    return await getAddress(c);
}