import React, { FC, useState, useEffect, CSSProperties } from "react";
import { useSpring, animated } from '@react-spring/web';
import { useNavigate } from 'react-router-dom';
import { Button } from "@mui/material";
import { useTheme, } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

export const ButtonLink: FC<{ url: string, title: string, delay: number, icon: object }> = (params) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const buttonStyle: CSSProperties = {
        backgroundColor: 'transparent',
        border: '3px solid transparent',
        borderImage: 'linear-gradient(to right, #FFC0CB, #FFFF99) 3',
        borderRadius: '0px',
        color: 'black',
        height: '50px',
        margin: '0vh',
        opacity: 1,
        whiteSpace: 'nowrap',
        width: '40vh',
        fontSize: isMobile ? '2vh' : '2vh' 
    };

    const navigate = useNavigate();
    const props = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        delay: params.delay,
        config: { duration: 1000 },
        margin: '1vh'
    });

    return (
        <animated.div style={{ ...props }}>
            <Button sx={{ marginLeft: '10vh', marginRight: '10vh' }} style={buttonStyle} onClick={() => navigate(params.url)}  >
                {params.title}
            </Button>
        </animated.div >
    );
}
