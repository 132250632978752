import { Container, Typography } from "@mui/material";
import { BackButton } from "../common/BackButton";
import { BEAUTYEVENT } from "./data";
import { Img } from "react-image";
import { LinkItem } from "./LinkItem";
import Flag from 'react-world-flags';
import { HeaderItem } from "./HeaderItem";

export default function BeautyEvents() {

    return (<Container>
        <BackButton url='/'></BackButton>

        <HeaderItem code='USA' title='BEAUTYCON'/>        
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Se celebra en diferentes ciudades de Estados Unidos, incluyendo Los Ángeles y Nueva York, y reúne a celebridades, influyentes y amantes de la belleza para exhibir productos, asistir a charlas y talleres de maquillaje, y más.
        </Typography>
        <br />
        <LinkItem text="Más Información" url='https://beautycon.com/' />
        <br />        
        <HeaderItem code='USA' title='IMATS'/>       
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            IMATS (The International Make-Up Artist Trade Show) - Es una feria comercial que se celebra en ciudades de todo el mundo, incluyendo Los Ángeles, Nueva York, Londres, Toronto, Sydney y más. IMATS reúne a artistas de maquillaje, fabricantes y distribuidores de productos para la industria del maquillaje.
        </Typography>
        <br />
        <LinkItem text="Más Información" url='https://imats.net/' />

        <HeaderItem code='USA' title='SEPHORA BEAUTY INSIDER COMMUNITY'/>        
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Sephora es una tienda de cosméticos con ubicaciones en todo el mundo y su Beauty Insider Community es una comunidad en línea donde los clientes pueden compartir información, aprender sobre nuevos productos y ver tutoriales de maquillaje.
        </Typography>
        <br />
        <LinkItem text="Más Información" url='https://community.sephora.com/' />

        <HeaderItem code='USA' title='THE MAKEUP SHOW'/>        
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Esta feria comercial se celebra en varias ciudades de Estados Unidos, incluyendo Nueva York, Chicago y Dallas, y cuenta con la presencia de marcas líderes, profesionales del maquillaje y expertos en belleza que presentan talleres y charlas.
        </Typography>
        <br />
        <LinkItem text="Más Información" url='https://www.themakeupshow.com/' />


        <HeaderItem code='ITA' title='COSMOPROF WORLDWIDE'/>    
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Es la feria de belleza más grande del mundo y se celebra en Bolonia, Italia. El evento cuenta con la presencia de empresas de todo el mundo que exhiben sus productos y servicios para la industria de la belleza.
        </Typography>
        <br />
        <LinkItem text="Más Información" url='https://www.cosmoprof.com/en/' />


        <HeaderItem code='COL' title='BEAUTY SHOW COLOMBIA'/>        
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Esta feria de belleza y cuidado personal reúne a proveedores, fabricantes, profesionales del maquillaje y estilistas de cabello en Bogotá para presentar sus productos y servicios. Además, cuenta con conferencias y talleres educativos impartidos por expertos en belleza.
        </Typography>
        <br />
        <LinkItem text="Más Información" url='https://beautyshowcolombia.com/' />

        <HeaderItem code='COL' title='EXPO BELLEZA FEST'/>        
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Esta feria de belleza se celebra en varias ciudades de Colombia, incluyendo Bogotá, Medellín y Cali. Reúne a las marcas más importantes de la industria de la belleza, y presenta lo último en productos y servicios para el cuidado personal, así como talleres y charlas de expertos en belleza.
        </Typography>
        <br />
        <LinkItem text="Más Información" url='https://www.expobellezafest.com/' />

        <HeaderItem code='COL' title='COSMÉTICOS & BELLEZA'/>
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Esta feria de belleza se lleva a cabo en Medellín y es el lugar ideal para conocer a proveedores, fabricantes y distribuidores de productos de belleza, cuidado personal y cosmética. Aquí se presentan las últimas tendencias y novedades en productos de belleza y cuidado personal.
        </Typography>
        <br />
        <LinkItem text="Más Información" url='https://www.cosmeticosybelleza.com/' />

        <HeaderItem code='COL' title='HAIR & BEAUTY'/>        
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Hair & Beauty: Esta feria de belleza se celebra en Bogotá y es uno de los eventos más importantes para profesionales del cabello en Colombia. Reúne a peluqueros, estilistas, fabricantes y distribuidores de productos de belleza para presentar sus productos y servicios más recientes.
        </Typography>
        <br />
        <LinkItem text="Más Información" url='https://www.hairbeautycolombia.com/' />

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
    </Container >);

}