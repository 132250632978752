import { Container, List, ListItem } from "@mui/material";

export default function ServiceSummary() {


    return (<Container>

        <List>
            <ListItem sx={{ textAlign: 'center'}}>SERVICIO A DOMICILIO</ListItem>
            <ListItem sx={{ textAlign: 'center' }}>BOGOTA</ListItem>
            <ListItem sx={{ textAlign: 'center' }}>ATENCION TODOS LOS DIAS</ListItem>
            <ListItem sx={{ textAlign: 'center' }}>ELIJE TUS PRODUCTOS</ListItem>
            <ListItem sx={{ textAlign: 'center' }}>ATENCION INMEDIATA</ListItem>
        </List>

    </Container>);

}


