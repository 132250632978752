import { Container, Typography } from "@mui/material";
import { BackButton } from "../common/BackButton";
import { Img } from "react-image";
import { DEGREES } from "./data";
export default function UniversityDegrees() {
    return (<Container>

        <BackButton url='/'></BackButton>
        <Typography className="highlight" component="div" fontSize='12px'>
            <h3>GRADOS UNIVERSITARIOS</h3>
        </Typography>
        <br />

        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            ¡Hola! Si estás a punto de graduarte de la universidad
        </Typography>
        <br />
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            ¡Felicidades!<br /> Es un momento emocionante en tu vida y queremos ayudarte a que luzcas increíble en tu ceremonia de graduación.
        </Typography>
        <br />
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Ofrecemos servicios de maquillaje profesionales para estudiantes universitarias que desean lucir radiantes y seguras en su gran día.
        </Typography>
        <br />
        <Img alt='' style={{ width: '100%', borderRadius: '2%' }} src={DEGREES[1].img} />
        <br />
        <br />
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Nuestros artistas del maquillaje están altamente capacitados y tienen experiencia en crear looks personalizados que complementen tu estilo y te hagan sentir hermosa.
        </Typography>
        <br />
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Ya sea que quieras un maquillaje natural y elegante o un look más audaz y glamuroso, estamos aquí para ayudarte a crear la apariencia perfecta para tu ceremonia de graduación.
        </Typography>
        <br />

        <Typography className="highlight" component="div" fontSize='12px'>
            <h3>BASE PERFECTA</h3>
        </Typography>
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            La base es muy importante de cualquier buen maquillaje. Asegúrate de elegir una base que se adapte a tu tono de piel y tipo de piel. La base debe ser aplicada uniformemente para lograr un aspecto natural y sin manchas.
        </Typography>

        <Typography className="highlight" component="div" fontSize='12px'>
            <h3>OJOS QUE RESALTEN</h3>
        </Typography>
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Los ojos son una de las partes más importantes del maquillaje. Es importante elegir una sombra de ojos que complemente el color de tus ojos y un delineador de ojos que resalte tu mirada. También puedes considerar pestañas postizas para lograr un aspecto dramático.
        </Typography>

        <Typography className="highlight" component="div" fontSize='12px'>
            <h3>LABIOS PERFECTOS</h3>
        </Typography>
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Para un look sofisticado, los labios deben ser un aspecto destacado del maquillaje. Elije un color de labios que complemente tu piel y tu atuendo y asegúrate de aplicarlo uniformemente.
        </Typography>

        <Typography className="highlight" component="div" fontSize='12px'>
            <h3>CEJAS BIEN DEFINIDAS</h3>
        </Typography>
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Las cejas bien definidas son esenciales para crear un marco para tu rostro. Asegúrate de que tus cejas estén bien arregladas y definidas para lograr un aspecto elegante y sofisticado.
        </Typography>
        <Typography className="highlight" component="div" fontSize='12px'>
            <h3>DURABILIDAD</h3>
        </Typography>
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Es importante que el maquillaje dure durante toda la ceremonia de graduación y las fotos después. Asegúrate de usar productos de alta calidad y de aplicar una capa de fijador para mantener todo en su lugar.
        </Typography>
        <br />
        <br />
        <Typography>
            ¡Permítenos ser parte de tu día especial y hacer que te sientas hermosa y segura mientras te gradúas!
        </Typography>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
    </Container >)
}