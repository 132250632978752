import { Container, List, Typography } from "@mui/material";
import { BackButton } from "../common/BackButton";
import { Img } from "react-image";
import { CSSProperties } from "styled-components";
import HighlightedImage from "../common/HighlightedImage";
import AnimatedListItem from "../common/AnimatedListItem";

const imageStyle: CSSProperties =
{
    width: '100%',
    borderRadius: '2%'
}

export default function Repolarization() {

    const imagePath = require('./img/repolarization2.jpg');
    const imagePath2 = require('./img/repolarization3.jpg');

    const services = [
        "APLICACIÓN DE PRODUCTOS NUTRITIVOS",
        "SELLADO CON FUENTE DE CALOR",
        "HIDRATACIÓN PROFUNDA",
        "REESTRUCTURACIÓN CAPILAR",
        "REDUCCIÓN DEL FRIZZ",
        "BRILLO Y SUAVIDAD INSTANTÁNEOS",
        "REPARACIÓN DE DAÑOS",
        "RESULTADOS DURADEROS",
    ];

    return (
        <Container>
            <BackButton url='/'></BackButton>

            <Typography className="highlight" component="div" fontSize='12px'>
                <h3>REPOLARIZACIÓN</h3>
            </Typography>
            <Typography textAlign={"justify"} margin={'2vh'}>
                La repolarización capilar es un tratamiento que revitaliza y repara el cabello dañado por tintes o calor, devolviéndole brillo y suavidad. Nutre profundamente desde la raíz hasta las puntas, logrando una melena más saludable y manejable en poco tiempo. ¡Recupera la vitalidad de tu cabello y luce espectacular con este tratamiento!
            </Typography>

            <br />

            <HighlightedImage src={imagePath2} alt="Repolarizate ahora" />

            <Typography className="highlight" component="div" fontSize='12px'>
                <h3>QUE PUEDES LOGRAR</h3>
            </Typography>
            <br />

            <Container style={{ fontSize: '2vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {services.map((service, index) => (
                        <AnimatedListItem key={index} delay={index * 0.2}>{service}</AnimatedListItem>
                    ))}
                </List>
            </Container>

            <Img style={imageStyle} src={imagePath} />


            <br />
            <br />
            <br />
            <br />

        </Container>
    );
}