import { FC } from "react";
import { Divider, Typography } from "@mui/material";
import Flag from "react-world-flags";

export const HeaderItem: FC<{ code: string, title: string }> = (params) => {

    return (
        <Typography component="div" align="center" fontSize='12px' sx={{ width: '100%' }} >
            <br />
            <Divider />
            <h3 style={{ display: 'flex', alignItems: 'center', width: '100%' }}><Flag style={{ marginRight: '2vh' }} code={params.code} height='20vh' /><span>{params.title}</span></h3>
        </Typography >);
}