import { useParams } from "react-router-dom";
import { getProduct } from '../../services/HairStore/ProductsDao';
import { FC, useState } from "react";
import NumberFormat from 'react-number-format';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Alert, Box, Container, ImageList, ImageListItem, Typography } from "@mui/material";
import { IProduct } from "./IProduct";
import { getMultimeMedia } from "../../services/HairStore/MultimediaDao";
import { isMobile } from 'react-device-detect';
import { BackButton } from "../../common/BackButton";

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const columns = isMobile ? 1 : 3;

export const Product: FC<{ initProduct?: IProduct }> = (props) => {

  const [copied, setCopied] = useState(false);
  const { id } = useParams();
  let product: IProduct | null = null;

  if (id != null) {
    let productId = parseInt(id!);
    product = getProduct(productId)!;
  } else {

    product = props.initProduct!;
  }

  let m = getMultimeMedia(product.id);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000); // Ocultar el mensaje después de 2 segundos (ajusta el tiempo según tus necesidades)
      })
      .catch((error) => {
        console.error('Error al copiar al portapapeles: ', error);
      });
  };

  return (<Container>
    <BackButton url='/productos'></BackButton>
    <br />
    <br />


    <Typography component="div" fontSize='12px' textAlign='center'>
      <h3>
        <strong>
          {product.title} <ContentCopyIcon style={{ cursor: 'pointer' }} onClick={handleCopyToClipboard} />
        </strong>
      </h3>
    </Typography>


    {copied && (
      <Alert severity="success">Se ha copiado al portapapeles</Alert>
    )}

    <ImageList
      sx={{ width: "100%", height: 'auto' }}
      variant="quilted"
      cols={columns}
      style={{ textAlign: 'center' }}
    >
      {m!.Images.map((item) => (
        <ImageListItem key={item.Original} cols={1} rows={1} >
          <img
            {...srcset(item.Original, 1, 1, 1)}
            alt={product!.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>

    <div className="description" dangerouslySetInnerHTML={{ __html: product.description }} ></div>

    <Typography textAlign='right'>
      <span>Precio:</span>
      <NumberFormat
        value={product.price}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
      />
    </Typography>
    <br />
    <Box sx={{ border: '1vh', borderRadius: '1vh', padding: '2vh' }} style={{ margin: '2vh', backgroundColor: 'whitesmoke' }}>
      Ventas directamente por Whatsapp por nequi o efecty, comunicate ahora mismo.
    </Box>
    <br />
    <br />
    <br />
    <br />
  </Container >
  );
}