import { IMenuItem } from "./IMenuItem";
import BrushIcon from '@mui/icons-material/Brush';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { CSSProperties } from "styled-components";

const iconStyle: CSSProperties =
{
    position: 'absolute',
    left: '0vh',
    marginLeft: '1vh',
    color:'gray'
}

const MENU: IMenuItem[] =
    [
        {            
            id: 1,
            img: require('./img/keratina.jpg'),
            text: 'Transforma tu cabello con nuestra keratina profesional. Consigue un cabello más liso, suave y brillante en cada aplicación. ¡Vive la experiencia de un salón de belleza en tu hogar!',
            title: 'KERATINA',
            to: '/keratina',
            icon: <BrushIcon style={iconStyle} />
        },                              
        {
            id: 2,
            img: require('./img/repolarizacion.jpg'),
            text: 'Revitaliza y rejuvenece tu cabello con nuestro tratamiento de repolarización. Alisa mientras nutre profundamente cada hebra para un brillo duradero y un aspecto saludable. ¡Dale a tu cabello el cuidado que merece!',
            title: 'REPOLARIZACIÓN',
            to: '/repolarizacion',
            icon: <StorefrontIcon style={iconStyle} />
        }  , 
        {
            id: 3,
            img: require('./img/eventosbelleza.jpg'),
            text: 'Una emprendedora de productos capilares logró expandir su negocio gracias al apoyo familiar, redes sociales y recomendaciones en academias de belleza.',
            title: 'CASO DE EXITO',
            to: '/exito',
            icon: <StorefrontIcon style={iconStyle} />
        }  
        
        
    ]

export default MENU;