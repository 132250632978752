import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardHeader } from '@mui/material';
import { Img } from 'react-image';
import ReactPlayer from 'react-player';
import { CDNUrl } from '../common/config';
import { BackButton } from '../common/BackButton';
import HighlightedImage from '../common/HighlightedImage';
import { alignProperty } from '@mui/material/styles/cssUtils';

const SuccessStory = () => {

  const afterVideoUrl = `${CDNUrl}/history/after.mp4`;
  const beforeImageUrl = `${CDNUrl}/history/before.jpg`;

  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <BackButton url='/' />
      <Typography variant="h4" gutterBottom align="center" >
        Caso de Éxito: Crecimiento a través del Apoyo Familiar y las Redes Sociales
      </Typography>

      <Typography variant="body1" paragraph align="justify">
        Esta es la historia de una emprendedora que, apoyada por su familia y amistades, logró crear y distribuir un
        producto innovador en la industria de la belleza capilar. Desde sus inicios, contó con el apoyo de su madre,
        hermanas, tías y amigas, quienes fueron sus primeras modelos y clientes, ayudando a posicionar el producto.
      </Typography>

      <Typography sx={{ textAlign: 'center' }}>
        <HighlightedImage
          style={{
            width: '30vh',
            textAlign: 'center'
          }}
          src={beforeImageUrl} alt="antes del tratamiento" />
      </Typography>

      <Typography className="highlight" component="div">
        <h3>NACIDO DE LA PASIÓN</h3>
      </Typography>

      <Typography variant="body1" paragraph align="justify">
        Mientras estudiaba en una academia de belleza, la emprendedora desarrolló un producto para el cabello
        enfocado en el alisado, aunque no le agradaba la idea de la decoloración. Gracias a la colaboración con
        su profesor, un estilista que me brindó apoyo, decidió presentar su producto en la academia.
      </Typography>

      <Typography variant="body1" paragraph align="justify">
        Durante una capacitación, aplicó su producto a una modelo con un cabello muy abundante, lo que impresionó
        a todos los presentes, incluidos los estudiantes. El resultado fue tan notable que las estilistas
        comenzaron a pedir el producto para usarlo en sus propios clientes.
      </Typography>

      <Typography className="highlight" component="div">
        <h3>ÉXITO EN REDES SOCIALES</h3>
      </Typography>
      <Typography variant="body1" paragraph align="justify">
        El boca a boca y las redes sociales fueron cruciales para la expansión del negocio. Las estudiantes que
        participaron en la capacitación comenzaron a recomendar el producto a otras personas. Con solo 30,000
        pesos por tarro, los estilistas podían trabajar con varias modelos, aumentando sus ganancias de manera
        eficiente.
      </Typography>
      <Typography variant="body1" paragraph align="justify">
        Gracias a este enfoque, el producto se vendió rápidamente en academias y entre otros profesionales del
        sector de la belleza, lo que permitió a la emprendedora seguir creciendo y expandiendo su negocio.
      </Typography>

      <Typography className="highlight" component="div">
        <h3>RESULTADO FINAL</h3>
      </Typography>

      <Typography sx={{ textAlign: 'center' }}>
        <ReactPlayer
          muted loop={true} volume={0} playing={true}
          width='auto'
          height='40vh'
          url={afterVideoUrl}
        />
      </Typography>

      <br />
      <br />
      <br />
    </Container>
  );
}

export default SuccessStory;
