
import { Container, ImageListItem, ImageListItemBar, Typography } from "@mui/material";
import { FC } from "react";
import { BackButton } from "../common/BackButton";
import COURSES from './data';

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export const Courses: FC<{}> = () => {

  return (
    <Container >
      <BackButton url='/' />
      <Typography className="highlight" component="div" fontSize='12px'>
        <h3>CLASES</h3>
      </Typography>
      <br />
      <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
        Aquí encontrarás todo mi conocimiento, consejos de cuidado capilar y mucho más. Un espacio creado para los amantes del cuidado del cabello, donde aprenderás y mejorarás tu salud.
      </Typography>
      <br />
      <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
        Invitándote a crear nuevos looks, despertar tu creatividad y así expresarte de la mejor manera.
      </Typography>
      <br />
      <Container style={{ textAlign: 'center' }}><strong>CLASES DE CUIDADO CAPILAR PROFESIONAL</strong></Container>
      <br />
      <ul>
        <li>Aprende a cuidar tu cabello de forma sencilla y efectiva.</li>
        <li>Los mejores consejos para un cabello sano y brillante.</li>
        <li>Cómo sacar el máximo provecho a tus productos capilares.</li>
        <li>Descubre nuevos productos del vasto mundo del cuidado capilar.</li>
      </ul>
      <br />
      <Typography className="highlight" component="div" fontSize='12px'>
        <h3>CUIDADO DIARIO</h3>
      </Typography>
      <Container style={{ textAlign: 'center' }}><strong>FUNDAMENTOS DE CUIDADO CAPILAR</strong></Container>

      <ul>
        <li>Higiene y protocolo en tratamientos capilares</li>
        <li>Productos y herramientas para el cuidado del cabello</li>
        <li>Tipos de cabello y preparación adecuada</li>
        <li>Teoría del color aplicada a la coloración capilar</li>
        <li>Correcciones del color en tratamientos capilares</li>
        <li>Aplicación de tratamientos y acabados finales</li>
        <li>Morfología del rostro y estilismo capilar</li>
        <li>Técnicas de iluminación y contorno para el cabello</li>
      </ul>

      <Typography className="highlight" component="div" fontSize='12px'>
        <h3>CLASES / TEORICO - PRACTICO</h3>
      </Typography>

      <br />
      <Typography><strong>CLASES TEÓRICO PRÁCTICAS</strong></Typography>
      <Typography><strong>MÓDULOS</strong></Typography>
      <br />
      <Container style={{ display: 'flex', flexDirection: 'column' }}>
        <span>1. Preparación y cuidado del cuero cabelludo</span>
        <span>Aprenderás técnicas de cuidado capilar</span>
        <br />
        <span>2. Color y Cabello</span>
        <span>Contornos, correcciones e iluminación capilar</span>
        <br />
        <span>3. Tratamientos capilares intensivos</span>
        <span>Fortalece y revitaliza tu cabello</span>
        <br />
        <span>4. Estilizado para el día a día - Básico</span>
        <br />
        <span>5. Técnicas de peinado</span>
        <span>Diversas técnicas de estilizado</span>
        <span>Aplicación de extensiones</span>
        <span>Diseño de peinados personalizados</span>
        <br />
        <span>6. Técnicas de cuidado del cabello</span>
        <br />
        <span>7. Tratamiento capilar completo</span>
        <span>Incluye todos los módulos</span>
        <br />
      </Container>
      <Typography className="highlight" component="div" fontSize="12px">
        <h3>INCLUYE</h3>
      </Typography>
      <ul>
        <li>Kit de productos capilares</li>
        <li>Producto sorpresa</li>
        <li>Extensiones capilares (opcional)</li>
        <li>Refrigerio</li>
      </ul>


      <br />
      <Typography textAlign="justify" style={{ textAlignLast: 'left' }}>
        Aquí están las diferentes opciones que tienes para mejorar la salud y el aspecto de tu cabello.
      </Typography>
      <br />
      <Typography textAlign="justify" style={{ textAlignLast: 'left' }}>
        Recuerda que tienes flexibilidad. Puedes programar tus tratamientos capilares en diferentes fechas y adaptarlos a tus necesidades con opciones modulares.
      </Typography>
      <br />
      <Typography className="highlight" component="div" fontSize="12px">
        <h3>BENEFICIOS DE TRATAMIENTOS CAPILARES</h3>
      </Typography>

      <ul>
        <li>Garantizamos un cabello sano y revitalizado durante semanas.</li>
        <li>Tu cabello lucirá espectacular en fotos, con un brillo y suavidad excepcionales.</li>
        <li>Es la elección perfecta para prepararte para una ocasión especial.</li>
        <li>Adquieres conocimiento sobre el cuidado capilar y técnicas personalizadas.</li>
        <li>Quedarás completamente satisfecha con los resultados.</li>
      </ul>
      <br />
      <br />

      <Typography textAlign="justify" component="div" style={{ textAlignLast: 'left', color: 'gray' }}>
        Nuestros tratamientos incluyen:
        <br />
        <ul>
          <li>Fundamentos del cuidado capilar</li>
          <li>Inducción al tratamiento adecuado</li>
          <li>Técnicas avanzadas de cuidado</li>
          <li>Tratamientos para principiantes</li>
          <li>Tratamiento personalizado</li>
          <li>Tratamiento intensivo</li>
          <li>Tratamiento virtual</li>
          <li>Tratamiento grupal</li>
        </ul>
      </Typography>


      <br />
      <br />
      <br />
      <br />
    </Container >
  );
}
