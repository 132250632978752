import { CDNUrl } from "../common/config";
const url = `${CDNUrl}/models`;

const HAIRSTYLES =
    [
        { rows: 1, cols: 2, title: 'Estilos originales', description: 'Elige el tuyo', img: `${url}/01.jpg` },
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${url}/02.jpg` },
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${url}/07.jpg` },
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${url}/06.jpg` },                                          
    ];

export default HAIRSTYLES;