import { Container, Typography } from "@mui/material";
import { Img } from "react-image";
import { BackButton } from "../common/BackButton";
import { MARRIAGE } from './data';


export default function PlanList() {

    return (
        <Container>
            <BackButton url='/'></BackButton>

            <Typography className="highlight" component="div" fontSize='12px'>
                <h3>SESION DE TRABAJO CAPILAR</h3>
            </Typography>
            <ul>
                <li>Desinfección y preparación de herramientas para tratamientos capilares.</li>
                <li>Exfoliación del cuero cabelludo.</li>
                <li>Hidratación profunda para el cabello.</li>
                <li>Aplicación de mascarillas capilares nutritivas.</li>
                <li>Masaje capilar relajante y estimulante.</li>
                <li>Selección y aplicación de productos capilares según las necesidades del cabello.</li>
                <li>Tratamiento de reparación intensiva para cabello dañado.</li>
                <li>Técnicas de corte y estilizado adaptadas a la forma del rostro.</li>
                <li>Aplicación de extensiones o postizos capilares (opcional).</li>
                <li>Alisado o peinado profesional.</li>
                <li>Tratamiento capilar sencillo para acompañante.</li>
            </ul>

            <Typography textAlign='justify' style={{ textAlignLast: 'right' }}>
                $ 80.000
            </Typography>
            <br />
            <Typography className="highlight" component="div" fontSize='12px'>
                <h3>TRATAMIENTO COMPLETO</h3>
            </Typography>
            <ul>
                <li>Asesoría personalizada para la salud y el estilo del cabello días previos al evento.</li>
                <li>Exfoliación del cuero cabelludo.</li>
                <li>Hidratación profunda del cabello.</li>
                <li>Aplicación de mascarillas capilares para preparar el cabello | Hidratantes.</li>
                <li>Masaje capilar relajante y revitalizante.</li>
                <li>Tratamientos de alisado o moldeado capilar previo al evento.</li>
                <li>Selección y aplicación de productos capilares según las necesidades de cada tipo de cabello.</li>
                <li>Estilizado profesional para la ocasión.</li>
                <li>Diseño de corte y peinado adaptado al rostro de la clienta.</li>
                <li>Peinado especial para el día del evento.</li>
                <li>Aplicación de accesorios para el peinado.</li>
                <li>Extensiones o postizos capilares (opcional).</li>
                <li>Tratamiento de brillo capilar para un acabado radiante.</li>
                <li>Servicio de peinado sencillo para 2 acompañantes.</li>
                <li>Dos fotografías profesionales del resultado final en PDF.</li>
                <li>Puntualidad en la atención.</li>
                <li>Servicio a domicilio.</li>
            </ul>

            <Typography textAlign='justify' style={{ textAlignLast: 'right' }}>
                $ 90.000
            </Typography>
            <br />
            <Typography>Revision del cabello días previos a tu evento OPCIONAL</Typography>
            <Typography textAlign='justify' style={{ textAlignLast: 'right' }}>
                $ 20.000
            </Typography>
            <br />

            <Typography>RECOMENDACIONES</Typography>
            <ul>
                <li>No llegar maquillado.</li>
                <li>Tener el pelo lavado | ocupa shampoo fuera de óleos, sin sal y parabenos, neutralizar con abundante agua</li>
                <li>Puntualidad.</li>
            </ul>            

            <Typography>
                AGENDA CON ANTICIPACIÓN
                Mínimo 1 día para preparar la temática
                deseada | SERVICIO A DOMICILIO
            </Typography>
            <br />
            <Typography textAlign='justify' component="div" style={{ textAlignLast: 'left' }}>
                Descubre la mejor versión de tu cabello, capturando ese momento único con un look impecable, perfecto para tus redes, fotos familiares, o un book fotográfico que resalte tu estilo y salud capilar.
            </Typography>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </Container>
    );
}