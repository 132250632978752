import logo from './WhatsApp.png';
import { CSSProperties } from "styled-components";
import { GOOGLE_ADS, SendConversion } from '../googleAds/GoogleAdsMethods';
import { motion } from 'framer-motion';

export default function WhatsappButton() {
  const phoneNumber = '+57 310 4230852';
  const message = 'Hola quisiera agendar cita con ustedes';
  const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;

  const handleClick = () => {
    // Notifica la conversión en Google Ads    
    SendConversion(GOOGLE_ADS.WHATSAPPBUTTON);
    window.open(url, '_blank');
  };

  const elementStyle: CSSProperties = {
    alignItems: 'center',
    backgroundColor: '#25d366',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    height: '7vh',
    justifyContent: 'space-around',
    margin: '1vh',
    right: '1vh',
    width: '7vh',
    zIndex: '999',
  };

  return (
    <motion.div
      onClick={handleClick}
      style={elementStyle}
      animate={{
        rotate: [0, -10, 10, -10, 10, 0],  // Efecto de vibración
      }}
      transition={{
        duration: 0.5,  // Duración de la animación
        ease: "easeInOut",
        repeat: Infinity,  // Repetir indefinidamente
        repeatDelay: 5,    // Repetir cada 5 segundos
      }}
    >
      <img src={logo} alt="WhatsApp" style={{ width: '4vh', height: '4vh' }} />
    </motion.div>
  );
}
