import { Link } from "react-router-dom";
import LinkIcon from '@mui/icons-material/Link';
import { FC } from "react";

export const LinkItem: FC<{ url: string, text: string }> = (params) => {

    return (
        <Link to={params.url} target="blank" style={{ display: 'flex', alignItems: 'center' }}>
            <LinkIcon style={{ marginRight: '8px' }} />
            <span style={{ verticalAlign: 'middle' }}>{params.text}</span>
        </Link>);
}