import React from 'react';
import { motion } from 'framer-motion';

// Definimos la interfaz para los props, incluyendo un prop de estilos personalizados
interface HighlightedImageProps {
  src: string;
  alt: string;
  style?: React.CSSProperties; // Este sigue siendo opcional
}

const HighlightedImage: React.FC<HighlightedImageProps> = ({ src, alt, style = {} }) => { // Valor por defecto vacío
  return (
    <motion.div
      whileHover={{ scale: 1.05, boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.3)" }}
      transition={{ type: 'spring', stiffness: 300 }}
      style={{ display: 'inline-block', borderRadius: '10px', overflow: 'hidden', ...style }} // Aplicamos los estilos personalizados
    >
      <img src={src} alt={alt} style={{ width: '100%', height: 'auto' }} />
    </motion.div>
  );
};

export default HighlightedImage;
