import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { ListItem } from '@mui/material';

// El archivo se convierte en un módulo con esta exportación
const AnimatedListItem: React.FC<{ children: React.ReactNode, delay: number }> = ({ children, delay }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,  // La animación se activa una vez que el ítem es visible
    threshold: 0.1,     // Se activa cuando el 10% del ítem es visible
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: 100 }}    // Comienza transparente y desplazado a la derecha
      animate={inView ? { opacity: 1, x: 0 } : {}}  // Activa la animación solo si es visible
      transition={{ duration: 0.5, delay }}       // Controla la velocidad y añade un retardo
    >
      <ListItem>{children}</ListItem>  {/* Cambié a <li> para usar dentro de <List> */}
    </motion.div>
  );
};

export default AnimatedListItem;
