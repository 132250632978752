import { GtagEventData, Global } from './GoogleAds';

export enum GOOGLE_ADS {
  APPOINTMENT,
  WHATSAPPBUTTON
}

const codes = {
  [GOOGLE_ADS.APPOINTMENT]: 'AW-11122940751/ifpoCNPUtJgYEM-26rcp',
  [GOOGLE_ADS.WHATSAPPBUTTON]: 'AW-11122940751/qQKOCJrU6pcYEM-26rcp',
};

export function SendConversion(eventIdentifier: GOOGLE_ADS) {
  const windowGlobal = window as Global;
  windowGlobal.dataLayer = windowGlobal.dataLayer || [];
  windowGlobal.dataLayer.push({
    'event': 'conversion',
    'send_to': codes[eventIdentifier]
  });


  if (windowGlobal.gtag) {
    const eventData: GtagEventData = {
      'send_to': codes[eventIdentifier]
    };

    windowGlobal.gtag('event', 'conversion', eventData);
  }
}