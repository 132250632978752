
import { Box, Container } from "@mui/material";
import { FC } from "react";
import QRCode from "react-qr-code";
import { BackButton } from "../common/BackButton";

export const LinkCode: FC<{}> = () => {

    return (
        <Container style={{ padding: '2vh',borderRadius: '4vh', backgroundColor:'transparent' }}>
            <BackButton url='/'></BackButton>
            <Container style={{ textAlign: 'center', background: 'black', display: 'flex', justifyContent: 'center', verticalAlign: 'middle' }}>
                <Box style={{ textAlign: 'center', background: 'white', padding: '16px', borderRadius: '4vh' }}>
                    <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "auto" }}
                        value="https://www.PaulaAlejandra.com"
                    />
                </Box>
            </Container >
        </Container>
    );
}