import { Container, ImageList, ImageListItem, ImageListItemBar, Typography } from "@mui/material";
import { BackButton } from "../common/BackButton";
import HairTreatmentS from './data';

function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

export default function HairTreatment() {
    return (
        <Container>
            <BackButton url='/'></BackButton>
            <Typography className="highlight" component="div" fontSize='12px'>
                <h3>TRATAMIENTO CAPILAR</h3>
            </Typography>
            <br />

            <Typography>
                Un tratamiento capilar para nutrir y mejorar el cabello consiste en la aplicación de productos ricos en vitaminas, minerales, y proteínas que penetran profundamente en la fibra capilar. Este proceso revitaliza y fortalece el cabello, devolviéndole su elasticidad, brillo y suavidad natural. Ideal para cabellos dañados o secos, este tratamiento repara y protege, dejando el cabello saludable y lleno de vida.
            </Typography>

            <br/>

            <Typography>
               <h3><strong>TRATAMIENTO COMPLETO</strong></h3>
            </Typography>            

            <Typography>
               Evaluación Inicial
            </Typography>
            <Typography>
                El proceso comienza con una evaluación detallada del estado actual de tu cabello. Se analizan factores como la textura, nivel de daño, sequedad, frizz, y las necesidades específicas del cuero cabelludo.
            </Typography>
            
            <Typography>
                Primera Sesión (Semana 1)
            </Typography>
            <ul>
                <li>
                    <Typography>
                        <strong>Limpieza Profunda:</strong> Se realiza un lavado con un champú clarificante para eliminar residuos de productos, contaminantes, y exceso de grasa, preparando el cabello para absorber mejor los tratamientos.
                    </Typography>
                </li>
                <li>
                    <Typography>
                        <strong>Tratamiento de Nutrición Intensa:</strong> Aplicación de una mascarilla nutritiva rica en queratina, vitaminas y aceites esenciales. Este tratamiento se deja actuar durante 30-60 minutos, utilizando calor para maximizar la absorción de los nutrientes.
                    </Typography>
                </li>
                <li>
                    <Typography>
                        <strong>Corte de Puntas:</strong> Si es necesario, se realiza un corte ligero para eliminar puntas abiertas y dar forma al cabello.
                    </Typography>
                </li>
            </ul>

            <Typography>
                Mantenimiento Semanal (Semanas 2-4)
            </Typography>
            <ul>
                <li>
                    <Typography>
                        <strong>Mascarillas Hidratantes y Reparadoras:</strong> Cada semana, se aplica una mascarilla específica según las necesidades del cabello (hidratación, reparación, o fortalecimiento). Estos tratamientos se pueden realizar tanto en casa como en el salón, dependiendo de la rutina establecida.
                    </Typography>
                </li>
                <li>
                    <Typography>
                        <strong>Sérum y Aceites Nutritivos:</strong> Uso regular de sérums o aceites capilares para mantener la hidratación y proteger el cabello de agresores externos como el calor o la contaminación.
                    </Typography>
                </li>
            </ul>

            <Typography>
                Reevaluación y Ajustes (Semana 4)
            </Typography>
            <ul>
                <li>
                    <Typography>
                        <strong>Evaluación de Progreso:</strong> Al finalizar el primer mes, se realiza una nueva evaluación del cabello para revisar el progreso. Se ajustan los productos y tratamientos según los resultados observados.
                    </Typography>
                </li>
                <li>
                    <Typography>
                        <strong>Tratamiento de Refuerzo:</strong> Se aplica un tratamiento intensivo de refuerzo, similar al de la primera sesión, para consolidar los resultados obtenidos.
                    </Typography>
                </li>
            </ul>

            <Typography>
                Mantenimiento y Finalización (Mes 2)
            </Typography>
            <ul>
                <li>
                    <Typography>
                        <strong>Continuación del Cuidado Semanal:</strong> Durante el segundo mes, se continúa con la aplicación semanal de mascarillas y el uso diario de productos nutritivos como sérums y aceites.
                    </Typography>
                </li>
                <li>
                    <Typography>
                        <strong>Tratamiento Final:</strong> Al finalizar el segundo mes, se aplica un último tratamiento intensivo para sellar la cutícula del cabello, aportando un brillo duradero y asegurando que el cabello mantenga su salud y vitalidad por más tiempo.
                    </Typography>
                </li>
            </ul>

            <ImageList
                sx={{ width: "100%", height: 'auto' }}
                variant="quilted"
                cols={2}
            >
                {HairTreatmentS.map((item) => (
                    <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                        <img
                            {...srcset(item.img, 1, item.rows, item.cols)}
                            alt={item.keywords}
                            loading="lazy"
                        />
                        <ImageListItemBar
                            sx={{ backgroundColor: item.title.length === 0 ? 'transparent' : '' }}
                            title={item.title}
                            subtitle={item.description}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <br />
            <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
                Una de las razones por las que el cuidado capilar es tan gratificante para mí es porque me permite transformar cómo te sientes con tu cabello. Mi objetivo es brindarte un toque personal y asegurarte que tu cabello luzca exactamente como lo deseas, acompañándote en esos momentos especiales para que te sientas segura y radiante.
            </Typography>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </Container>

    );

}