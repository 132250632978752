import Geocode from "react-geocode";

export const getAddress = async (coordinate: any) => {

    return new Promise<string>((resolve, reject) => {
        Geocode.setApiKey("AIzaSyDZwogdDSrM69faFkJZqARLPmbBKgK6Y1g");

        // set response language. Defaults to spanish.
        Geocode.setLanguage("es");

        // set response region. Its optional.
        // A Geocoding request with region=es (Spain) will return the Spanish city.
        Geocode.setRegion("co");

        // set location_type filter . Its optional.
        // google geocoder returns more that one address for given lat/lng.
        // In some case we need one address as response for which google itself provides a location_type filter.
        // So we can easily parse the result for fetching address components
        // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
        // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
        Geocode.setLocationType("ROOFTOP");

        // Enable or disable logs. Its optional.
        // Geocode.enableDebug();   

        // Get address from latitude & longitude.
        Geocode.fromLatLng(coordinate.lat, coordinate.lng).then(
            (response) => {
                const address = response.results[0].formatted_address;
                resolve(address);
            },
            (error) => {
                return reject(error.message);
            }
        );
    });



}